* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

@font-face {
  font-family: GT-black;
  src: url('assets/fonts/GTWalsheimPro-Black.ttf');
}

@font-face {
  font-family: GT-bold;
  src: url('assets/fonts/GTWalsheimPro-Bold.ttf');
}

@font-face {
  font-family: GT-medium;
  src: url('assets/fonts/GTWalsheimPro-Medium.ttf');
}

@font-face {
  font-family: GT-regular;
  src: url('assets/fonts/GTWalsheimPro-Regular.ttf');
}

@font-face {
  font-family: GT-light;
  src: url('assets/fonts/GTWalsheimPro-Light.ttf');
}

@font-face {
  font-family: GT-thin;
  src: url('assets/fonts/GTWalsheimPro-Thin.ttf');
}

h1,
h2 {
  font-family: GT-black;
}

h3,
h4 {
  font-family: GT-bold;
}

h5 {
  font-family: GT-Medium;
}

h6 {
  font-family: GT-regular;
}

p,
a,
small {
  font-family: GT-regular;
}

.btn-blue {
  background-color: #1633A3 !important;
  ;
  /* font-family: C-book; */
  color: #fff !important;
  font-size: 15px;
}

.btn-blue:hover {
  background-color: #1633A3 !important;
  /* font-family: C-book; */
  color: #fff !important;
  font-size: 15px;
}


.app {
  position: relative;
}


.main-modal {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  padding: 50px 10px;
  overflow-y: scroll;
}

.main-modal .close-modal {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -10%;
  cursor: pointer;
}

.main-modal-content {
  /* background-color: #fff; */
  border-radius: 10px;
  /* padding: 20px; */
  position: relative;
  animation: 0.5s slide-down ease-in-out;
  border: none !important;
  border-radius: 20px 20px 0px 0px !important;

}


.overlay-option {
  padding: 20px 0px 10px 0px;
  display: flex;
  color:rgb(32, 32, 32);
  cursor:pointer
}
.overlay-option i{
  font-size: 28px;
  margin-right: 15px;
}


.footer-area p{
  margin-top: 20px;
  font-weight: 800;
  background: -webkit-linear-gradient(53.82deg, #1633A3 2.15%, #A514C9 95.03%) !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

/*------------------ Privacy modal------------------- */
.privacy-modal .main-modal-content .main-modal-header {
  background: linear-gradient(#1633A3, rgba(0, 35, 173, 0.75), #1633A3), url('assets/wave.png');
  background-repeat: no-repeat;
  min-height: 200px;
  background-size: cover;
  border-radius: 20px 20px 0px 0px !important;
  color: #fff;
  width: 100%;
}
.privacy-modal .main-modal-content .spec-area {
  display:flex;
  margin: 20px 10px 0px 0px;
  color:#1633A3;
}
.privacy-modal .main-modal-content .main-modal-body .spec-area i{
  font-size: 30px;
  margin-right: 20px;
}

/*------------------ privacy modal ends here------------------- */

/*------------------ Banks modal------------------- */
.banks-modal .main-modal-content .main-modal-header {
  display: flex;
  color: #1633A3;
  justify-content: space-between;
  align-items: center;
}
.banks-modal .main-modal-content .main-modal-header i {
  font-size: 32px;
  color: #1633A3;
  cursor: pointer
}

.banks-modal .main-modal-content .search-input {
  background-color: #F7FAFF;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
}
.banks-modal .main-modal-content .search-input input {
  background-color: transparent !important;
  border: none !important;
  color: #1633A3 !important;
  font: 15px GT-medium;
}
.banks-modal .main-modal-content .search-input input::placeholder {
  color: #1633A3 !important;
  font: 15px GT-medium;
}

.banks-modal .main-modal-content .tab-area {
  display: flex;
  border-bottom: 2px solid #EDEDED;
  text-align: center;

}
.banks-modal .main-modal-content .tab-area h5 {
  padding: 10px 10px;
  min-width: 50%;
  color: #1633A3;
  margin: 0px
}
.banks-modal .main-modal-content .tab-area h5.active {
  border-bottom: 2px solid #1633A3
}

.banks-modal .main-modal-content .bank-list {
  border: 1px solid #1633A3;
  height: 400px;
  overflow: scroll;
  border-radius: 10px;
  margin: 10px 0px;
}
.banks-modal .main-modal-content .bank-list div {
  border-bottom: 1px solid #97A0AF;
  padding: 10px 15px;
  background-color: #D2E4FF47;
  display: flex;
  align-items: center;
  cursor:pointer;
}
.banks-modal .main-modal-content .bank-list img,
.bank-logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.banks-modal .main-modal-content .bank-list p {
  color: #1633A3;
  margin: 0;
  padding-left: 15px;
}

.banks-modal .main-modal-content .bank-overlay,  .active-bank-modal .main-modal-content .bank-overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  margin:0 auto;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 11;
  border-radius: 20px 20px 0px 0px !important;
}
.banks-modal .main-modal-content .bank-overlay .card-body,  .active-bank-modal .main-modal-content .bank-overlay .card-body {
  width: 100%;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  border-radius: 20px 20px 0px 0px !important;
}
.banks-modal .main-modal-content .bank-overlay .bank-overlay-header{
  display: flex;
  align-items: center;
  width:55%;
  justify-content: space-between;
  margin-left: auto ;
}
.banks-modal .main-modal-content .bank-overlay .bank-overlay-header i, .active-bank-modal .main-modal-content .bank-overlay .bank-overlay-header i {
  font-size: 25px;
  cursor: pointer;
}
.banks-modal .main-modal-content .bank-overlay .overlay-option, .active-bank-modal .main-modal-content .bank-overlay .overlay-option{
  padding: 20px 0px 10px 0px;
  display: flex;
  color:rgb(32, 32, 32);
  cursor:pointer
}
.banks-modal .main-modal-content .bank-overlay .overlay-option i, .active-bank-modal .main-modal-content .bank-overlay .overlay-option i{
  font-size: 28px;
  margin-right: 15px;
}
/*------------------ Banks modal ends here------------------- */


/*------------------ Active Bank modal------------------- */
.privacy-modal.active-bank-modal .main-modal-content .main-modal-header {
  background-repeat: no-repeat !important;
  background-position: center center;
  min-height: 200px;
  background-size: cover !important;
  border-radius: 20px 20px 0px 0px !important;
  color: #fff;
  width: 100% !important;
}
.privacy-modal.active-bank-modal .main-modal-content .bank-logo {
  width:80px;
  height:80px
}

.privacy-modal.active-bank-modal  .tab-area {
  display: flex;
}
.privacy-modal.active-bank-modal  .tab-area h5 {
  padding: 10px 10px;
  min-width: 50%;
  color: #000;
  margin: 0px;
  padding:15px 10px
}

.privacy-modal.active-bank-modal .main-modal-content .login-inputs input {
  padding:15px 10px ;
  border-radius: 10px;
}

.privacy-modal.active-bank-modal .main-modal-content .login-inputs input,
.privacy-modal.active-bank-modal .main-modal-content .login-inputs  textarea {
  padding:15px 10px ;
  border-radius: 10px;
  font: 15px GT-medium;
}
.privacy-modal.active-bank-modal .main-modal-content .login-inputs input::placeholder 
.privacy-modal.active-bank-modal .main-modal-content .login-inputs textarea::placeholder {
  font: 15px GT-medium;
}

.active-bank-modal .main-modal-content .bank-overlay .bank-overlay-header{
  display: flex;
  align-items: start;
  width:100%;
  justify-content: space-between;
  margin-left: auto ;
}
.active-bank-modal .main-modal-content .bank-overlay .overlay-option.sec-question{
  display:block;
}


.privacy-modal.active-bank-modal .main-modal-content .success-gif {
  width:200px;
  height:200px
}
/* .banks-modal .main-modal-content .main-modal-header {
  display: flex;
  color: #1633A3;
  justify-content: space-between;
  align-items: center;
}
.banks-modal .main-modal-content .main-modal-header i {
  font-size: 32px;
  color: #1633A3;
  cursor: pointer
} */


/*------------------ Active Bank modal ends here------------------- */


/* responsiveness starts here */

@media screen and (max-width:1025px) {}

@media screen and (max-width: 768px) {
  .privacy-modal.active-bank-modal  .tab-area h5 {
    font-size: 17px;
  }

}

@media screen and (max-width:600px) {
  .main-modal {
    width: 100vw;
    min-height: 100vh;
    z-index: 10;
    padding: 100px 5px;
  }

  .main-modal-content {
    padding: 0px;
  }

  .main-modal .close-modal {
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1%;
    right: 2%;
    cursor: pointer;
    z-index: 10;
  }

}


/* responsiveness ends here */